import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AllPromotions from './AllPromotions';
import { useDispatch, useSelector } from 'react-redux';
import * as commonActions from "../../redux/actions/common";
import * as productsActions from "../../redux/actions/products"
import * as promotionsActions from "../../redux/actions/promotions"
import { getAllPromotion } from "../../redux/actions/promotions"
import CustomInput from '../../components/common/CustomInput';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
// import SpecialProductsList from './SpecialProductsList';
// import InfoIcon from '@material-ui/icons/Info';
import CommonLoading from '../../components/common/CommonLoading';
import _ from 'lodash';
import Select from 'react-select'
import { colorStyles } from '../../utils';
import {
    Button,
    Grid,
    InputAdornment,
    FormControl,
    InputLabel,
    // Select,
    MenuItem
} from '@material-ui/core';

function PromotionsPage() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [filterStatus, setFilterStatus] = useState("All");
    const { specialProducts = {}, allPromotions, isLoading } = useSelector(state => state.promotions);
    const [filters, setFilters] = useState({
        limit: 10,
        skip: 0,
        page: 0,
    })

    // const [specialProductsList, setSpecialProductsLits] = useState([]);
    const [syncLoader, setSyncLoader] = useState(false)

    // const { values = [] } = specialProducts || {}
    const { values: promotionsValues = [] } = allPromotions;

    //Promotion
    const hitGetAllPromotionAPI = () => {
        let filter;
        if (filterStatus === "All") filter = ""
        else filter = filterStatus
        dispatch(promotionsActions.getAllPromotion({
            searchTerm: search,
            isActive: filter,
        }))
    };

    useEffect(() => {
        dispatch(productsActions.getAllProducts({ productTags: "featured", limit: 200 }))
        hitSpProductsAPI();
    }, [])

    useEffect(() => {
        if (search?.length === 0) hitGetAllPromotionAPI()
        else searchDebounce({ search })
    }, [search, filterStatus]);

    // useEffect(() => {
    //     setSpecialProductsLits([...(values || [])])
    // }, [values])


    const handleConfirmationPromotion = (id) => {
        dispatch(commonActions.openCustomModalDialog({
            title: "Are you sure you want to Delete?",
            confirm: () => { handleDeletePromotion(id) },
            cancel: () => { }
        }, "ConfirmationModal"))
    }

    const handleDeletePromotion = (id) => {
        dispatch(promotionsActions.deletePromotion({ promotionId: id }))
            .then(res => { hitGetAllPromotionAPI() })
            .catch(error => { console.log(error) })
        }

    const handleEditPromotion = (item) => {
        dispatch(commonActions.openCustomModalDialog({ data: item }, "EditPromotion"))
    }

    const syncPromotions = () => {
        setSyncLoader(true)
        promotionsActions.syncPromotion().then(() => {
            setSyncLoader(false)
            hitGetAllPromotionAPI()
        }).catch(() => setSyncLoader(false))
    }

    //Special Products
    const hitSpProductsAPI = () => {
        dispatch(promotionsActions.getSpecialProducts())
            // .then((res) => { hitGetAllPromotionAPI(); })
            .then((res) => { })
            .catch((error) => { console.log(error) })
    };

    const onClickClearInputField = () => {
        if (!search) return
        const newFilters = { limit: 10, skip: 0, page: 0, search: '' }
        setFilters(newFilters)
        // hitRequest({ ...newFilters, cartType: isActiveTab })
        setSearch('')
    }


    // const handleConfirmationProduct = (id) => {
    //     dispatch(commonActions.openCustomModalDialog({
    //         title: "Are you sure you want to Delete?",
    //         confirm: () => { handleDeleteProduct(id) },
    //         cancel: () => { }
    //     }, "ConfirmationModal"))
    // }

    // const handleDeleteProduct = (id) => {
    //     let updatedList = [...specialProductsList]
    //     let items = updatedList.filter(v => v.id !== id)

    //     setSpecialProductsLits([...items])

    //     let newOrderIds = items.map(v => v.id)
    //     dispatch(promotionsActions.updateSpecialProducts({ products: newOrderIds }))
    // }

    // const addSpecialProduct = (id) => {
    //     dispatch(commonActions.openCustomModalDialog({}, "AddSpecialProduct"))
    // }
    const addPromotion = () => {
        dispatch(commonActions.openCustomModalDialog({}, "AddPromotions"))
    }

    // const onUpdateListOrder = (items) => {
    //     setSpecialProductsLits([...items])
    //     let newOrderIds = [...items].map(v => v.id)
    //     dispatch(promotionsActions.updateSpecialProducts({ products: newOrderIds }))
    // }

    //search  debounce
    const searchDebounce = useCallback(
        _.debounce(({ search }) => {
            if (search?.length) dispatch(promotionsActions.getAllPromotion({ searchTerm: search }));
        }, 1200),
        []
    );

    //filter Options
    const filterOptions = [
        { value: 'All', label: 'All' },
        { value: true, label: 'Active' },
        { value: false, label: 'In-Active' },
    ];

    //filter Change
    const onHandleChangeFilter = (e) => {
        const value = e?.value;
        setFilterStatus(value)
    }

    return (
        <>
            <Grid item xs={4} className="d-flex search-section top-btn-sec">
                <CustomInput
                    label="Search"
                    name="search"
                    placeholder="Search"
                    onChange={e => setSearch(e?.target?.value || "")}
                    value={search}
                    fullWidth
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                        endAdornment: search ? <InputAdornment position="end" className="cur-pointer"><ClearIcon onClick={onClickClearInputField} /></InputAdornment> : null
                    }}
                />
            </Grid>
            <Grid item xs={8} className="manage-top-tabs" >
                <Select
                    isSearchable={false}
                    defaultValue={filterOptions[0]}
                    onChange={onHandleChangeFilter}
                    options={filterOptions}
                            styles={colorStyles}
                />
                {/* <div className="tabs-section-div" >
                    <FormControl variant="outlined" style={{ background: '#46952f' }} >
                        <InputLabel >Filter</InputLabel>
                        <Select
                            value={filterStatus}
                            onChange={onHandleChangeFilter}
                            label="Filter"
                            style={{ color: 'white' }}

                        >
                            <MenuItem value={"All"} >All</MenuItem>
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>InActive</MenuItem>
                        </Select>
                    </FormControl> */}
                {/* <div className={filterStatus === '' ? 'selected-tab' : ''} onClick={() => setFilterStatus('')}>
                        <span>All</span>
                    </div>
                    <div className={filterStatus === true ? 'selected-tab' : ''} onClick={() => setFilterStatus(true)}>
                        <span>Active</span>
                    </div>
                    <div className={filterStatus === false ? 'selected-tab' : ''} onClick={() => setFilterStatus(false)}>
                        <span>InActive</span>
                    </div> */}
                {/* </div> */}


                <div className='add-category-btn ml-1'>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={addPromotion}
                        className=""
                    >
                        Add Promotions
                    </Button>
                </div>
                {/* </Grid> */}
                {/* </Grid> */}

                {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={syncPromotions}
                >
                    {syncLoader ? <CircularProgress size={20} style={{ color: "white" }} /> : 'Sync Promotions'}
                </Button> */}
            </Grid>

            <Grid item xs={12}>
                <AllPromotions
                    handleDeletePromotion={handleConfirmationPromotion}
                    promotionsValues={promotionsValues || []}
                    handleEditPromotion={handleEditPromotion}
                    // isActiveTab={isActiveTab}
                    searchTerm={search}

                />
            </Grid>


            {/* <Grid item xs={12} className="promotions-specials-sec">
                <div className="specials-top-sec">
                    <Typography className="info"><InfoIcon /> Products Visible in carousel at Home Page.</Typography>
                    <Button
                        onClick={() => { addSpecialProduct() }}
                        variant="contained"
                        color="primary"
                    >
                        Add Product
                    </Button>
                </div>
                <SpecialProductsList
                    handleDeleteList={handleConfirmationProduct}
                    list={specialProductsList || []}
                    onUpdateListOrder={onUpdateListOrder}
                />
            </Grid> */}

            <CommonLoading isLoading={isLoading} />
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },

}));


export default PromotionsPage;