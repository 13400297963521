import React, { useState, useEffect, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTheme, styled } from '@mui/material/styles';
import { useChart } from '../../components/chart/';
import { fNumber } from '../../utilities/formatNumber'
import { Card, CardHeader } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import * as dashBoardActions from '../../redux/actions/dashboard';
import { useDispatch } from 'react-redux';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import RangePicker from "react-range-picker"
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import GetAppIcon from '@material-ui/icons/GetApp';

const CHART_HEIGHT = 547;
const LEGEND_HEIGHT = 102;

const StyledChartWrapper = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));
export default function Coupon({
  title,
  subheader,
  Colors,
  chartData,
  ...other
}) {

  const [selectedDateRange, setSelectedDateRange] = useState({
    start: dayjs().startOf('month'),
    end: dayjs(),
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch();

const endate = new Date(endDate);
const startdate = new Date(startDate)

// Format the endDate
const formattedEndDate = endate.toLocaleDateString('en-GB', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
});
// Format the Start
const formattedStartDate = startdate.toLocaleDateString('en-GB', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
});



  useEffect(() => {
    const startOfMonth = selectedDateRange.start;
    const endOfDay = selectedDateRange.end;
    setStartDate(startOfMonth);
    setEndDate(endOfDay);
    dispatch(dashBoardActions.getCouponData(startOfMonth.unix() * 1000, endOfDay.unix() * 1000));
  }, [selectedDateRange]);

  const handleDateRangeChange = (startDate, endDate) => {
    if (startDate && endDate) {
      setSelectedDateRange({
        start: dayjs(startDate),
        end: dayjs(endDate),
      });


    }
  };
 
  const theme = useTheme();
  const chartLabels = chartData?.map((i) => i.label);
  const chartSeries = chartData?.map((i) => i.value);

  const chartOptions = useChart({
    colors: Colors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
          },
        },
        offsetY: 0,
        offsetX: 0,
      },
      dataLabels: {
        dropShadow: {
          enabled: false,
        },
        style: {
          fontSize: '14px',
          fontFamily: 'Roboto',
        },
      },
    },
    chart: {
      animations: {
        enabled: true,
        easing: 'linear',
        dynamicAnimation: {
          speed: 500,
        },
      },
    },
  });
  


  const data = useMemo(() => {
    const transformedData = chartData.map((item) => ({
      name: item.label,
      value: item.value,
    }));
    return transformedData;
  }, [chartData]);

  const headers = [
    { label: "Promo Name", key: "name" },
    { label: "Usage Count", key: "value" },
  ];
  
  const csvReport = {
    data: data,
    headers: headers,
    filename: `AppliedCouponsList-${formattedStartDate}-to-${formattedEndDate}.csv`
  };



  return (
    <Card {...other}>
      <div className="graph-head">
        <CardHeader title={title} className='title-heading' />
        <div className="date-csv-sec">
          <RangePicker
            value={selectedDateRange}
            onDateSelected={handleDateRangeChange}
          />
          <div className="export-csv">
            <CSVLink {...csvReport}>
              Export CSV
              <GetAppIcon />
            </CSVLink>
          </div>
        </div>
      </div>
      <StyledChartWrapper dir="ltr">

        <div >
          {chartSeries.length !== 0 ?
            <ReactApexChart
              type="donut"
              series={chartSeries}
              options={chartOptions}
              height={410}
            />
            :
            <div className="min-no-data">
              <p>No Data Found</p>
            </div>
          }

        </div>
      </StyledChartWrapper>
    </Card>
  );
};

