import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import * as orderActions from "../../redux/actions/orders"
import { useLocation, useHistory } from "react-router-dom"

import AllOrders from './AllOrders';
import CustomInput from '../../components/common/CustomInput';
import _ from 'lodash';

import SearchIcon from '@material-ui/icons/Search';
import { InputAdornment, Tooltip, Zoom, CircularProgress } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Select from 'react-select'
import { colorStyles } from '../../utils';

const queryString = require('query-string');



export default function ManageProducts(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const [search, setSearch] = useState("")
    // const [filterStatus, setFilterStatus] = useState("Active");
    // const {colorStyles } = props;

    const [filters, setFilters] = useState({
        limit: 10,
        skip: 0,
        page: 0,
    })
    const [isActiveTab, setIsActiveTab] = useState("")

    useEffect(() => {
        let newFilters = {}
        let searchT = ''
        let newActive = 'active'
        if (location.search) {
            const { limit = 10, skip = 0, page = 0, searchTerm = '', cartType = 'active' } = queryString.parse(location.search);
            searchT = searchTerm
            newActive = cartType
            newFilters = {
                limit: Number(limit),
                skip: Number(skip),
                page: Number(page),
                searchTerm: searchT,
                cartType: newActive
            }
            setIsActiveTab(newActive)
            setFilters(newFilters)
            setSearch(searchT)
        }
        hitRequest({ ...newFilters })

    }, [])

    useEffect(() => {
        location.search = queryString.stringify({ ...filters, searchTerm: search, cartType: isActiveTab });
        history.push({
            pathname: '/orders',
            search: location.search
        })
    }, [filters, search, isActiveTab])


    const hitRequest = (filters = {}) => {
        dispatch(orderActions.getAllOrders({ ...filters }))

    }

    const handleChange = (e) => {
        const { value } = e.target
        let newFilters = { limit: 10, skip: 0, page: 0, searchTerm: value, cartType: isActiveTab }
        setSearch(value);
        setFilters({ limit: 10, skip: 0, page: 0 })
        debouncedSave(newFilters);
    }

    const debouncedSave = useCallback(_.debounce(filters => hitRequest(filters), 500), []);

    const onChangePagination = (e, page) => {
        const skip = page * filters.limit
        setFilters({ ...filters, skip, page })
        hitRequest({ ...filters, skip, page, cartType: isActiveTab, searchTerm: search })
    }

    const onChangeRowsPerPage = (e) => {
        const newFilters = { limit: parseInt(e.target.value, 10), page: 0, skip: 0, searchTerm: search }
        setFilters(newFilters)
        hitRequest({ ...newFilters, cartType: isActiveTab })
    }

    const onClickClearInputField = () => {
        if (!search) return
        const newFilters = { limit: 10, skip: 0, page: 0, searchTerm: '' }
        setFilters(newFilters)
        hitRequest({ ...newFilters, cartType: isActiveTab })
        setSearch('')
    }

    //filter Options
    const filterOptions = [
        { value: true, label: 'Abandoned' },
        { value: 'all', label: 'All' },
        { value: true, label: 'Active' },
    ];

    const onChangeSelectedTab = (e) => {
        const status = e?.value;
        const newFilters = { limit: 10, skip: 0, page: 0, searchTerm: search }
        setIsActiveTab(status)
        setFilters(newFilters)
        hitRequest({ ...newFilters, cartType : status })
    }


    return (
        <Grid item xs={12} className="manage-product-section" style={{ marginBottom: "20px" }}>
            <Grid container>

                <Grid item xs={4} className="d-flex search-section">
                    <CustomInput
                        label="Search"
                        name="search"
                        placeholder="Search"
                        onChange={handleChange}
                        value={search}
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                            endAdornment: search ? <InputAdornment position="end" className="cur-pointer"><ClearIcon onClick={onClickClearInputField} /></InputAdornment> : null
                        }}
                    />
                </Grid>
                <Grid item xs={4}></Grid>

                <Grid item xs={4} className="pb-1 manage-top-tabs">
                     <Select
                    isSearchable={false}
                    defaultValue={filterOptions[0]}
                    onChange={onChangeSelectedTab}
                    options={filterOptions}
                    styles={colorStyles}
                />
                    {/* <div className="tabs-section-div">
                        <div className={isActiveTab === 'active' ? 'selected-tab' : ''} onClick={() => onChangeSelectedTab('active')}>
                            <span>Active</span>
                        </div>
                        <div className={isActiveTab === 'abandoned' ? 'selected-tab' : ''} onClick={() => onChangeSelectedTab('abandoned')}>
                            <span>Abandoned</span>
                        </div>
                        <div className={isActiveTab === 'all' ? 'selected-tab' : ''} onClick={() => onChangeSelectedTab('all')}>
                            <span>All</span>
                        </div>
                    </div> */}

                </Grid>
            </Grid>


            <AllOrders
                onChangePagination={onChangePagination}
                onChangeRowsPerPage={onChangeRowsPerPage}
                filters={filters}
                searchTerm={search}
                isActiveTab={isActiveTab}
            />
        </Grid>
    );
}