import React, { Fragment } from 'react';
import { Grid, TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomTable from "../../components/common/CustomTable"
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';


const useStyles = makeStyles((theme) => ({
    root: {
    },

}));

function AllDelivery(props) {
    const classes = useStyles();

    const { deliveryHours, isLoading, handleEdit, handleDelete } = props

    return (
            <Grid item xs={12} className="trucks-table">
                <CustomTable
                    tableHeading={["Location Name", "Location City", "Delivery Days", "Zip Codes", "Actions"]}
                    rowsData={createDeliveryHours(deliveryHours, { handleEdit, handleDelete })}
                    isLoading={isLoading}
                />
            </Grid>
    );
}


export default AllDelivery;


function createDeliveryHours(data, action) {
    const { handleEdit, handleDelete } = action

    return data && data.length ?
        data.map((item, index) => {
            const { locationName, locationCity, deliveryDays, zipcode } = item;
            const day = Object.keys(deliveryDays).map(day => day[0] + day.substring(1).toLowerCase()).join(", ");
            const zipcodeJoined = zipcode.join(', ');
            return (
                <Fragment key={index}>
                    <TableCell>{locationName}</TableCell>
                    <TableCell>{locationCity}</TableCell>
                    <TableCell>{day}</TableCell>
                    <TableCell>{zipcodeJoined}</TableCell>
                    <TableCell>
                        <div className="delivery-hours-actions">
                            <div className="cur-pointer edit-icon" onClick={() => { handleEdit(item) }}>
                                <EditIcon />
                            </div>
                            <div className="cur-pointer delete-icon" onClick={() => { handleDelete(item) }}>
                                <DeleteOutlineIcon />
                            </div>
                        </div>
                    </TableCell>
                </Fragment>
            );
        }) : []

}