import { apiGet } from "../../utils";

export function getCouponAPI(startDate,endDate){
   return apiGet(`api/v1/integrations/top/selling/coupons?startDate=${startDate}&endDate=${endDate}`);
}

// export function getProductDetailAPI(data){
//    return apiGet(`api/v1/integrations/location/wise/data`,data);
// }

export function getProductDetailAPI(startDate,endDate){
  return apiGet(`api/v1/integrations/location/wise/data?startDate=${startDate}&endDate=${endDate}`);
}

export function getProductLocationAPI(data) {
   return apiGet(`api/v1/integrations/location/wise/data?locationName=${(data)}`);
 }
 
 export function getProductsAPI(year) {
   return apiGet(`api/v1/integrations/top/selling/products?year=${year}`);
 }
   
 export function getDeviceAPI(startDate,endDate){
   return apiGet(`api/v1/integrations/get/order/count?startDate=${startDate}&endDate=${endDate}`);
 }