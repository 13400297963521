import React from 'react';
import { Button, TableCell } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CommonLoading from "../../components/common/CommonLoading"
import CustomProductTable from '../../components/common/CustomProductTable';
import * as commonActions from '../../redux/actions/common';
import { convertPascalCaseToUserFriendly } from '../../utils';

function AllOrders(props) {
    const { onChangePagination = () => { }, filters = {}, onChangeRowsPerPage = () => { }, searchTerm = '', isActiveTab = "active" } = props
    const { allOrders, isLoading } = useSelector(state => state.orders)
    const { values = [], total = 0 } = allOrders
    const dispatch = useDispatch()
    const isAllTabSelected = isActiveTab === "all"
    let tableHeading = ["S.no", "Customer email", "Total Items", "Sub total", "Discount", "Total Tax", "Delivery Fee", "Order Total", "View Items"]
    if (isAllTabSelected) {
        tableHeading.splice(2, 0, 'Order Status')
    }

    return (
        <React.Fragment>
            <CustomProductTable
                tableHeading={tableHeading}
                rowsData={createDataProducts(values, filters, searchTerm, isActiveTab, dispatch, isAllTabSelected)}
                total={total}
                filters={filters}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onChangePagination={onChangePagination}
            />
            <CommonLoading isLoading={isLoading} />
        </React.Fragment>
    );
}
export default AllOrders


function createDataProducts(data, filters, searchTerm, isActiveTab, dispatch, isAllTabSelected) {

    return data && data && data.length ?
        data.map((item, index) => {
            const {
                email = '',
                items = [],
                total = 0,
                totalDiscount = 0,
                subTotal = 0,
                totalCalcTax = 0,
                deliveryFee = 0,
                cartStatus = ''
            } = item
            return <React.Fragment key={index}>
                {/* <TableCell>
                    <Link to={`/manage_products/${id}?limit=${filters.limit}&skip=${filters.skip}&page=${filters.page}&searchTerm=${searchTerm}&active=${isActiveTab}`} className="link-tag">{name ? name : '-'}</Link>
                </TableCell> */}
                <TableCell >{((filters.page) * filters.limit) + (index + 1)}</TableCell>
                <TableCell>{email || "-"}</TableCell>
                {
                    isAllTabSelected ? <TableCell >{convertPascalCaseToUserFriendly(cartStatus) || "-"}</TableCell> : null
                }
                <TableCell>
                    {(Array.isArray(items) && items.length) || 0}

                </TableCell>
                <TableCell>${!isNaN(subTotal) && subTotal.toFixed(2) || 0}</TableCell>
                <TableCell>${!isNaN(totalDiscount) && totalDiscount.toFixed(2) || 0}</TableCell>
                <TableCell>${!isNaN(totalCalcTax) && totalCalcTax.toFixed(2) || 0}</TableCell>
                <TableCell>${!isNaN(deliveryFee) && deliveryFee.toFixed(2) || 0}</TableCell>
                <TableCell>${!isNaN(total) && total.toFixed(2) || 0} </TableCell>
                <TableCell width="140px">
                    <Button
                        onClick={(e) => { dispatch(commonActions.openCustomModalDialog({ items }, "ViewItems")) }}
                        variant="contained"
                        color="primary"
                        className="view-cart-item-btn"
                    >
                        View
                    </Button>
                </TableCell>
            </React.Fragment>
        }) : []
}