import { isEmpty } from "lodash";

export function validateUserData(data = {}) {
    let errors = {};
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!data.name) {
        errors.name = "Name is required!";
    }

    if (!data.email) {
        errors.email = "Email is required!";
    }

    if (data.email && !data.email.match(regexEmail)) {
        errors.email = "Invalid Email";
    }

    if (!data.password) {
        errors.password = "Password is required!";
    }
    if (data.password.length < 6) {
        errors.password = "Password should have atleast 6 character!";
    }

    if (data.confimPassword.length < 6) {
        errors.confimPassword = "Confirm Password have atleast 6 character!";
    }
    if (!data.confimPassword) {
        errors.confimPassword = " Confirm Password is required!";
    }
    if (data && data.password && data.confimPassword && data.password !== data.confimPassword) {
        errors.confimPassword = "Password doesn't match!";
    }

    return {
        isValid: isEmpty(errors),
        errors
    }

}