import { AccessTokenId, AccessTokenName, isSuperAdmin } from '../../constants';
import {
    GET_LAST_SYNC_DATE,
    GET_LAST_SYNC_DATE_FAILED,
    GET_LAST_SYNC_DATE_SUCCESSFULL,
    LOGIN_FALED,
    LOGIN_SUCCESSFULL,
    LOGOUT_USER,
    REQUEST_LOGIN,
    SET_USER,
} from '../../redux/actions/auth';
import { getUserData, isLoggedIn, removeData, setData } from '../../utils';


// import { removeData, setData } from '@utils';

const initialState = {
    user: getUserData() || {},
    isLoading: false,
    isLoggedIn: isLoggedIn() ? true : false,
    userPhone: "",
    lastSyncDate: ""
}

export default function auth(state = { ...initialState }, action = {}) {
    switch (action.type) {

        case SET_USER:
            return { ...state, user: action.user, isLoggedIn: true }

        //Login
        case REQUEST_LOGIN:
            return { ...state, isLoading: true }

        case LOGIN_SUCCESSFULL:
            setData(AccessTokenName, action?.user)
            setData(AccessTokenId, action?.user?.access_token)
            const superAdmin = action?.user?.isSuperAdmin || false
            setData(isSuperAdmin, superAdmin)
            return { ...state, user: action.user, isLoggedIn: true, isLoading: false }

        case LOGIN_FALED:
            return { ...state, isLoading: false, isLoggedIn: false }

        //get last sync date
        case GET_LAST_SYNC_DATE:
            return { ...state }

        case GET_LAST_SYNC_DATE_SUCCESSFULL:
            return { ...state, lastSyncDate: (action.data && action.data.lastSync) || "" }

        case GET_LAST_SYNC_DATE_FAILED:
            return { ...state }

        //Logout User
        case LOGOUT_USER:
            removeData(AccessTokenId)
            removeData(AccessTokenName)
            removeData(isSuperAdmin)
            removeData("adminLastSync")
            return { ...state, isLoggedIn: false }

        default:
            return state
    }
}