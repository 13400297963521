import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { apiGet, scrollIntoView } from '../../utils';
import Coupon from '../Dashboard/Coupon';
import * as dashBoardActions from '../../redux/actions/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CustomTable from '../../components/common/CustomTable';
import ProductDetail from './ProductDetail';
import Products from './Products';
import product from '../../redux/reducers/products';
import BarGraph from './BarGraph';
import CommonLoading from '../../components/common/CommonLoading';
import Product from './Products';
import Devices from './Device';

function DashBoardPage() {
  const [dashboardData, setDashboardData] = useState({});
  const dashboardStore = useSelector((state) => state.dashboard);

  const [showTable, setShowTable] = useState(false);
  const [showGraph, setShowGraph] = useState(false);
  const [productIndex, setProductIndex] = useState(0);

  const dispatch = useDispatch();
  const classes = useStyles();
  const { couponCounts } = dashboardStore?.CouponData || {};
  const isLoading = dashboardStore?.isLoading || false;
  const { values = [] } = dashboardStore?.ProductData || {};
  const [selectedLabel, setSelectedLabel] = useState(null);
  const locationData = useSelector(state => state.dashboard?.LocationData);
  const Products = useSelector(state => state.dashboard?.Products)
  const Device = useSelector(state => state.dashboard?.Device)


  const fetchData = async () => {
    try {
      const responses = await Promise.all([
        // dispatch(dashBoardActions.getCouponData()),
        dispatch(dashBoardActions.getProductDetail()),
        // dispatch(dashBoardActions.getProduct()),
        // dispatch(dashBoardActions.getDevice())
      ]);
      responses.forEach(response => {
        if (response.error) {

          console.error('API Error:', response.error);
        }
      });
    } catch (error) {

      console.error('Fetch Data Error:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (dashboardStore && dashboardStore?.data) {
      setDashboardData(dashboardStore?.data);

    }
  }, [dashboardStore]);


  const chartData = couponCounts
    ? couponCounts?.slice(1, 6).map(({ couponName, count }) => ({ label: couponName, value: count }))
    : [];

  const startIndex = productIndex;
  const endIndex = productIndex + 6;
  const currentProducts = values?.slice(startIndex, endIndex).map(({ _id, count }) => ({ label: _id, value: count }));

  const handleViewMoreProducts = () => {
    setProductIndex(productIndex + 6);
  };

  const handleViewPreviousProducts = () => {
    const newIndex = Math.max(productIndex - 6, 0);
    setProductIndex(newIndex);
  };

  const handleViewMore = () => {
    setShowTable(!showTable);
    setShowGraph(false);
    scrollIntoView("footer")

  };

  const handleCollapse = () => {
    setShowTable(false);
    setShowGraph(false);
    scrollIntoView("footer")
  };

  const handleLabelClick = (label) => {
    setSelectedLabel(label);
    setShowTable(false);
  };


  return (
    <>
      <Grid item xs={12} md={6} lg={6} className="mt-2">
        <div className='graph-ui box-shadow-ui'>
          <Product title="Top Selling Product " Products={Products} isLoading={isLoading} />
        </div>
      </Grid>
      <Grid item xs={12} md={6} lg={6} className='mt-2'>
        <Devices
          title="Total Orders Count"
          chartData={[
            { label: 'App Users', value: Device[0]?.appTotalOrders || 0 },
            { label: 'Web Users', value: Device[0]?.webTotalOrders || 0 },
          ]}
          webRevenue = {`Total Web Revenue : $${Device[0]?.webTotalRevenue ? Device[0]?.webTotalRevenue : "0"}`}
          appRevenue = {`Total App Revenue : $${Device[0]?.appTotalRevenue ? Device[0]?.appTotalRevenue : "0"}`}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6} className="mt-2">
        <div className='graph-ui'>
          <Coupon title="Applied Coupons List" chartData={chartData} />
          <div className='toggle-button'>
            {showTable ? (
              <Button variant="contained" size="small" onClick={handleCollapse}>Collapse</Button>
            ) : (
              <Button variant="contained" onClick={handleViewMore}>View More</Button>
            )}
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={6} lg={6} className="mt-2">
        <ProductDetail
          title="Ordered Locations"
          setSelectedLabel={handleLabelClick}
          selectedLabel={selectedLabel}
          productsData={currentProducts}
        />
        <div className="toggle-button">
          {productIndex >= 6 && (
            <Button variant="contained" style={{ marginRight: '10px' }} onClick={handleViewPreviousProducts}>
              Previous
            </Button>
          )}
          {currentProducts.length === 6 && (
            <Button variant="contained" style={{ marginLeft: '10px' }} onClick={handleViewMoreProducts}>
              Next
            </Button>
          )}
        </div>
      </Grid>
      {selectedLabel && !showTable && (
        <Grid item xs={12} md={12} lg={12} className="mt-2 graph-product">
          <BarGraph setSelectedLabel={handleLabelClick} selectedLabel={selectedLabel} locationData={locationData} />
        </Grid>
      )}
      <div id="coupon_table">
        {showTable && (
          <Grid item xs={12} md={12} lg={12} className="mt-2" >
            <CouponTable couponCounts={couponCounts} />
          </Grid>
        )}

      </div>
      <CommonLoading isLoading={isLoading} />

    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    // height: '100vh',
  },
}));


function CouponTable({ couponCounts, isLoading }) {
  const tableHeading = ['S.no', 'Coupon Name', 'Count'];
  const rowsData = createCouponTableCell(couponCounts);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} >
        <div className='cus-table'>
          <CustomTable
            tableHeading={tableHeading}
            rowsData={rowsData}
            isLoading={isLoading}
            showPagination={true}
          />
        </div>
      </Grid>
    </Grid>
  );
}

function createCouponTableCell(data = []) {
  return data && data.length ? data.map(({ couponName, count }, index) => (
    <React.Fragment key={index}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>{couponName || "-"}</TableCell>
      <TableCell>{count}</TableCell>
    </React.Fragment>
  )) : [];
}


export default DashBoardPage;
