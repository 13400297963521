import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { useHistory } from "react-router-dom"
import { Button, Typography, CircularProgress, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash";
import CustomInput from '../../components/common/CustomInput';
import { validateUserData } from '../../utilities/validations/user';
import * as actions from "../../redux/actions/user";
import * as commonActions from "../../redux/actions/common";
import { getData, } from '../../utils';

const initialUser = {
    name: "",
    email: "",
    password: "",
    confimPassword: ""
}

function UserModal(props) {
    const classes = useStyles();
    // const history = useHistory();
    const dispatch = useDispatch()

    const { modalData } = useSelector(state => state.common)
    const { adminLoader = false } = useSelector(state => state.user)

    const { title = "Add", data: editData = {} } = modalData

    const [user, setUser] = useState({ ...initialUser })
    const [errors, setErrors] = useState({ ...initialUser })
    const isEditdata = !_.isEmpty(editData)

    useEffect(() => {
        if (isEditdata) {
            setUser({ ...editData, password: "" })
        }
    }, [])

    const onChangehandler = (e) => {
        const { name = '', value = '' } = e.target || {}
        setUser({ ...user, [name]: value })
    }


    const addUser = () => {
        const { errors, isValid } = validateUserData(user)
        setErrors(errors);
        const { confimPassword, ...rest } = user;

        if (isValid) {
            const allAdminList = JSON.parse(getData('pineappleExpress-admin'))?.adminData;
            dispatch(actions.addUser(rest, (user._id || ''))).then(res => {
                dispatch(actions.getAllAdminData({ email: allAdminList[0]?.email }))
                dispatch(actions.getAllAdmin())
                dispatch(commonActions.closeCustomModalDialog())
            })
        }
    }


    return (
        <Grid container component="main" className={`${classes.root} add-zipcode-modal-container`}>
            <Grid item xs={12} className="pt-2">
                <Typography variant="h4" className="d-flex jc-ctr">{title} User</Typography>
            </Grid>

            <Grid item xs={12} className="add-category-input">
                <Grid item xs={12}>
                    <CustomInput
                        name="name"
                        value={user.name || ""}
                        onChange={onChangehandler}
                        label="Name"
                        className="w-100"
                        margin="normal"
                        error={errors.name}
                    />
                </Grid>
                <Grid item xs={12} >
                    <CustomInput
                        disabled={isEditdata}
                        name="email"
                        value={user.email || ""}
                        onChange={onChangehandler}
                        label="Email"
                        className="w-100"
                        margin="normal"
                        error={errors.email}
                    />
                </Grid>
                <Grid item xs={12} >
                    <CustomInput
                        type="password"
                        name="password"
                        value={user.password || ""}
                        onChange={onChangehandler}
                        label={!isEditdata ? "Password" : "New Password"}
                        className="w-100 "
                        margin="normal"
                        error={errors.password}
                    />
                </Grid>
                <Grid item xs={12} >
                    <CustomInput
                        type="password"
                        name="confimPassword"
                        value={user.confimPassword || ""}
                        onChange={onChangehandler}
                        label="Confirm Password"
                        className="w-100 "
                        margin="normal"
                        error={errors.confimPassword}
                    />
                </Grid>
                <Grid item xs={12} className="d-flex jc-ctr add-button">
                    <Button
                        onClick={addUser}
                        variant="contained"
                        color="primary"
                        fullWidth
                    >
                        {adminLoader ? <CircularProgress size={22} style={{ color: "white" }} /> : !isEditdata ? 'Submit' : "Update"}
                    </Button>
                </Grid>
            </Grid>

        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
    },

}));


export default UserModal