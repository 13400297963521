import { Button } from '@mui/material';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';

function BarGraph({ selectedLabel, locationData, setSelectedLabel, isLoading }) {
  console.log("locationData", locationData)
  const [activeSeries, setActiveSeries] = useState('Top Selling Products');
  if (!locationData || !locationData.values || locationData.values.length === 0) {
    return null;
  }

  const selectedData = locationData.values[0];

  const handleSeriesClick = (seriesName) => {
    setActiveSeries(seriesName);
  };

  const selectedSeriesData = {
    'Top Selling Products': selectedData.topSellingProducts,
    'Least Selling Products': selectedData.leastSellingProducts,
    'Promo Results': selectedData.promoResults,
  };

  const graphData = selectedSeriesData[activeSeries].map(item => ({
    x: item.productName || item._id,
    y: item.qty || item.count,
  }));

  const chartOptions = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    xaxis: {
      categories: graphData?.map(data => data.x),
    },
  };


  const series = [
    {
      name: activeSeries,
      data: graphData?.map(data => data.y),
    },
  ];

  return (
    <div className='cus-table p-2'>
      <h2 className='mt-2 in-block'>{selectedLabel}</h2>
      <div className='bar-graph-btn'>
        {Object.keys(selectedSeriesData).map(seriesName => (
          <button
            key={seriesName}
            onClick={() => handleSeriesClick(seriesName)}
            disabled={seriesName === activeSeries}
            className={seriesName === activeSeries ? 'disabled-btn' : undefined}
            isLoading={isLoading}
          >
            {seriesName}
          </button>
        ))}
      </div>
      <ReactApexChart options={chartOptions} series={series} type="bar" height={320} width={"100%"} />
    </div>
  );
}

export default BarGraph;
