import { apiGet, apiPost, apiPut, apiDelete } from '../../utils';

export function getSpecialsProductsAPI(data) {
    return apiGet(`api/v1/admin/products/specialOrder`);
}

export function updateSpecialProductAPI(data) {
    return apiPost(`api/v1/admin/products/specialOrder`, data);
}

export function syncPromotionAPI(data) {
    return apiPost(`api/v1/admin/promotions/sync`, data);
}

export function getAllPromotionAPI(data) {
    return apiGet(`api/v1/admin/promotions/all/promo`, data);
    // return apiGet(`api/v1/admin/promotions`, data);
}

export function updatePromotionAPI(data) {
    return apiPost(`api/v1/admin/promotions/update/promo`, data);
    // const { id, ...rest } = data
    // const formData = new FormData();

    // Object.keys(rest).map((item, index) => {
    //     let value = data[item]
    //     return (
    //         formData.append(item, value)
    //     )
    // })

    // return apiPut(`api/v1/admin/promotions/${id}`, formData);
}

export function deletePromotionAPI(data) {
    return apiDelete(`api/v1/admin/promotions/delete/promotion`, data);
}

export function addParmotionAPI(data) {
    return apiPost(`api/v1/admin/promotions/add/promo`, data);
}
