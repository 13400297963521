import { toast } from 'react-toastify';
import {getCouponAPI,getProductDetailAPI,getProductLocationAPI,getProductsAPI,getDeviceAPI } from '../api/dashboard';

export const GET_COUPON_DATA = `GET_COUPON_DATA`;
export const GET_COUPON_DATA_SUCCESS = `GET_COUPON_DATA_SUCCESS`;
export const GET_COUPON_DATA_FAILURE = `GET_COUPON_DATA_FAILURE`;


export function getCouponData(startDate,endDate){
    return dispatch => {
        dispatch({
            type: GET_COUPON_DATA
        })
        return new Promise((resolve, reject) => {
            getCouponAPI(startDate,endDate)
                .then((res) => {
                    dispatch({
                        type: GET_COUPON_DATA_SUCCESS,
                        payload: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_COUPON_DATA_FAILURE
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}


export const GET_PRODUCT_DETAIL = `GET_PRODUCT_DETAIL`;
export const GET_PRODUCT_DETAIL_SUCCESS = `GET_PRODUCT_DETAIL_SUCCESS`;
export const GET_PRODUCT_DETAIL_FAILURE = `GET_PRODUCT_DETAIL_FAILURE`;

export function getProductDetail(startDate,endDate){
    return dispatch => {
        dispatch({
            type: GET_PRODUCT_DETAIL
        })
        return new Promise((resolve, reject) => {
            getProductDetailAPI(startDate,endDate)
                .then((res) => {
                    dispatch({
                        type: GET_PRODUCT_DETAIL_SUCCESS,
                        payload: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_PRODUCT_DETAIL_FAILURE
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}

export const GET_PRODUCT_LOCATION = `GET_PRODUCT_LOCATION`;
export const GET_PRODUCT_LOCATION_SUCCESS = `GET_PRODUCT_LOCATION_SUCCESS`;
export const GET_PRODUCT_LOCATION_FAILURE = `GET_PRODUCT_LOCATION_FAILURE`;

export function getProductLocation(data){
    return dispatch => {
        dispatch({
            type: GET_PRODUCT_LOCATION
        })
        return new Promise((resolve, reject) => {
            getProductLocationAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_PRODUCT_LOCATION_SUCCESS,
                        payload: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_PRODUCT_LOCATION_FAILURE
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}

export const GET_PRODUCT='GET_PRODUCT';
export const GET_PRODUCT_SUCCESS='GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILED='GET_PRODUCT_FAILED';

export  function getProduct(year) {
    return dispatch => {
        dispatch({
            type: GET_PRODUCT,
        })
        return new Promise((resolve, reject) => {
            getProductsAPI(year)
            .then((res)=>{
                dispatch({
                    type: GET_PRODUCT_SUCCESS,
                    payload:res
                })
                return resolve(res)
            })
            .catch(err=>{
                dispatch({
                    type: GET_PRODUCT_FAILED,
                })
                toast.error(err)
                return reject(err)
            })
        })

    }
}

export const GET_DEVICE = `GET_DEVICE`;
export const GET_DEVICE_SUCCESS = `GET_DEVICE_SUCCESS`;
export const GET_DEVICE_FAILURE = `GET_DEVICE_FAILURE`;

export function getDevice(startDate,endDate){
    return dispatch => {
        dispatch({
            type: GET_DEVICE
        })
        return new Promise((resolve, reject) => {
            getDeviceAPI(startDate,endDate)
                .then((res) => {
                    dispatch({
                        type: GET_DEVICE_SUCCESS,
                        payload: res
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_DEVICE_FAILURE
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}