import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Orders from './Orders';
import { apiGet } from '../../utils';



function OrdersPage() {
    const classes = useStyles();

    return (
            <Orders />
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
}));


export default OrdersPage