import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom"
import { Button, Typography, FormGroup, Grid, FormLabel, FormControl, InputLabel, Select, MenuItem, Switch } from '@material-ui/core';
import * as promotionsActions from "../../redux/actions/promotions"
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../../constants';
import { numRegex } from "../../utils"
import CustomInput from '../../components/common/CustomInput';
import CustomDropDown from '../../components/common/CustomDropDown';
import CustomCheckbox from '../../components/common/CustomCheckbox';
import { validatePromotionData } from '../../utilities/validations/promotion';

function EditPromotions(props) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch()

    const { modalData } = useSelector(state => state.common)

    const { title = "Edit", data: editData = {} } = modalData;

    const [data, setPromotionData] = useState({})
    const [promotionImages, setPromotionImages] = useState("")
    const [errors, setErrors] = useState("")

    const dayCheckBoxData = [
        { name: "mon", label: "Monday", checked: data?.mon || false },
        { name: "tues", label: "Tuesday", checked: data?.tues || false },
        { name: "wed", label: "Wednesday", checked: data?.wed || false },
        { name: "thur", label: "Thursday", checked: data?.thur || false },
        { name: "fri", label: "Friday", checked: data?.fri || false },
        { name: "sat", label: "Saturday", checked: data?.sat || false },
        { name: "sun", label: "Sunday", checked: data?.sun || false },
    ];

    useEffect(() => {
        if (editData) {
            setPromotionData({ ...editData })
            setPromotionImages((editData.images && editData.images[0] && `${BASE_URL}${editData.images[0]}`) || '')
        }
    }, [])

    const handleChangePromotion = (e) => {
        const { name, value } = e.target;
        let requiredValue = value;
        if (name === "maxAvailable" || name === "limitPerCustomer" || name === "discountAmt") {
            requiredValue = Number(value);
            if (!(numRegex.test(requiredValue)) || requiredValue < 0) return;
            if (data?.discountType === "Percentage" && requiredValue > 100) return;
        }
        setPromotionData({ ...data, [name]: requiredValue })
        setErrors({ ...errors, [name]: "" })
    }

    const editPromotion = () => {
        const {
            // title,
            // description,
            id,
            name,
            active,
            promoCodes,
            enableMaxAvailable,
            maxAvailable,
            enableLimitPerCustomer,
            limitPerCustomer,
            promoDesc,
            mon,
            tues,
            wed,
            thur,
            fri,
            sat,
            sun,
            discountType,
            discountAmt,
            // promocode,
        } = data;
        const promoArr = (() => {
            if (promoCodes?.length > 1) return promoCodes?.split(",")
            else if (promoCodes?.length === 1) return promoCodes
            else return []
        })();


        let updatedData = {
            promotionId: id,
            name,
            active,
            promoCodes: promoArr,
            enableMaxAvailable,
            maxAvailable,
            enableLimitPerCustomer,
            limitPerCustomer,
            promoDesc,
            mon,
            tues,
            wed,
            thur,
            fri,
            sat,
            sun,
            discountType,
            discountAmt,
            // promocode,
        };

        if (promotionImages) {
            updatedData = { ...updatedData, images: promotionImages }
        }

        const { errors, isValid } = validatePromotionData(updatedData)
        setErrors(errors);

        if (isValid) {
            dispatch(promotionsActions.updatePromotion(updatedData)).then(() => {
                dispatch(promotionsActions.getAllPromotion())
                props.closeModal()
            }).catch(() => { })
        }
    }

    const onChangeStatus = (e) => {
        const { name, checked } = e.target;
        setPromotionData({ ...data, [name]: checked });
        // let updatedData = { ...data };
        // updatedData = { ...updatedData, active: val };
        // setPromotionData(updatedData);
    };

    const handleChangeImages = (files = []) => {
        let image = files[0]
        setPromotionImages(image)
    }

    return (
        <Grid container component="main" className={`${classes.root} add-category-modal-container`}>

            <Grid item xs={12} className="pt-2">
                <Typography variant="h4" className="d-flex jc-ctr">{title} Promotion</Typography>
            </Grid>
            <Grid item xs={12} className="add-category-input">
                {/* <CustomDropDown
                    setFile={(v) => { handleChangeImages(v) }}
                    src={promotionImages && typeof promotionImages == "string" ? promotionImages : promotionImages && (URL.createObjectURL(promotionImages) || "")}
                    imageProps={{ height: "200px" }}
                    accept=".jpeg,.jpg,.png"
                /> */}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={4}>
                        <CustomInput
                            name="name"
                            value={data?.name || ""}
                            onChange={(e) => { handleChangePromotion(e) }}
                            label="Title"
                            className="w-100"
                            error={errors.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <FormGroup row className="d-block">
                            {/* <FormLabel component="legend">Status</FormLabel> */}
                            <FormControl variant="outlined" className="w-100">
                                <InputLabel id="demo-simple-select-outlined-label">Active or Inactive </InputLabel>
                                <Select
                                    // fullWidth
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={data?.active ?? false}
                                    onChange={(e) => { handleChangePromotion(e) }}
                                    name='active'
                                    label="Active or InActive"
                                >
                                    <MenuItem value={false} >Inactive</MenuItem>
                                    <MenuItem value={true} >Active</MenuItem>
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4}>
                        <CustomInput
                            name="promoCodes"
                            value={data?.promoCodes || ""}
                            onChange={(e) => { handleChangePromotion(e) }}
                            label="Promo Code"
                            className="w-100"
                        />
                    </Grid>
                    <Grid item xs={4} className="m-0">
                        <Switch
                            name="enableMaxAvailable"
                            checked={data?.enableMaxAvailable || false}
                            onChange={onChangeStatus}
                        />
                        <CustomInput
                            name="maxAvailable"
                            type="text"
                            label="Max Available"
                            className="w-100 mt-0"
                            value={data?.maxAvailable || ""}
                            disabled={!data?.enableMaxAvailable}
                            onChange={(e) => { handleChangePromotion(e) }}
                        />
                    </Grid>


                    <Grid item xs={4}>
                        <Switch
                            name="enableLimitPerCustomer"
                            checked={data?.enableLimitPerCustomer || false}
                            onChange={onChangeStatus}
                        />
                        <CustomInput
                            name="limitPerCustomer"
                            type="text"
                            label="Max Per Customer"
                            className="w-100 mt-0 product-align-double-meta"
                            value={data?.limitPerCustomer || ""}
                            disabled={!data?.enableLimitPerCustomer}
                            onChange={(e) => { handleChangePromotion(e) }}
                        />
                    </Grid>

                    <Grid item xs={12} >
                        <div className="pt-2 bg-border">
                            <Typography >Apply On </Typography>
                            {
                                dayCheckBoxData.map((data) => {
                                    const { name, label, checked } = data
                                    return <CustomCheckbox
                                        key={name}
                                        name={name}
                                        label={label}
                                        checked={checked}
                                        onChange={onChangeStatus}
                                    />
                                })
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} className="">
                        <CustomInput
                            name="promoDesc"
                            value={data.promoDesc || ""}
                            onChange={(e) => { handleChangePromotion(e) }}
                            label="Description"
                            className="w-100"
                            margin="normal"
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl variant="outlined" className="w-100 perAmount-mt product-align-single-meta">
                            <InputLabel id="demo-simple-select-outlined-label">Percentage or Amount</InputLabel>
                            <Select
                                fullWidth
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={data.discountType || ""}
                                onChange={(e) => { handleChangePromotion(e) }}
                                name='discountType'
                                label="Percentage or Amount"
                            >
                                <MenuItem value={'Percentage'} >Percentage</MenuItem>
                                <MenuItem value={'Cash'}  >Cash</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <CustomInput
                            name="discountAmt"
                            label="Amount"
                            type="text"
                            className="w-100 product-align-single-meta"
                            margin="normal"
                            value={data?.discountAmt || ""}
                            onChange={(e) => { handleChangePromotion(e) }}
                            disabled={!(data?.discountType?.length)}
                        />
                    </Grid>
                    <Grid item xs={4} className="paddingTop-24 btn-custom">
                        <Button onClick={() => { editPromotion() }} variant="contained" color="primary">Submit</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
    },

}));


export default EditPromotions