import React, { useEffect, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTheme, styled, makeStyles } from '@mui/material/styles';
import { useChart } from '../../components/chart/';
import { fNumber } from '../../utilities/formatNumber'
import { Card, CardHeader, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as dashboardActions from '../../redux/actions/dashboard';
import BarGraph from './BarGraph';
import { saveBlazeZipcodes } from '../../redux/actions/appointments';
import { Button, IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../../components/common/Loader';
import { CSVLink } from 'react-csv';
import RangePicker from "react-range-picker"
import dayjs from 'dayjs';
import * as dashBoardActions from '../../redux/actions/dashboard';
import GetAppIcon from '@material-ui/icons/GetApp';

const CHART_HEIGHT = 579;
const LEGEND_HEIGHT = 102;

const StyledChartWrapper = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));
export default function ProductDetail({
  title,
  subheader,
  Colors,
  dashboardStore,
  productsData,
  setSelectedLabel,
  selectedLabel,
  ...other
}) {

  const theme = useTheme();
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: dayjs().startOf('month'),
    end: dayjs(),
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const chartLabels = productsData?.map((i) => i.label);
  const chartSeries = productsData?.map((i) => i.value);

  const endate = new Date(endDate);
  const startdate = new Date(startDate)
  
  // Format the endDate
  const formattedEndDate = endate.toLocaleDateString('en-GB', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
  // Format the Start
  const formattedStartDate = startdate.toLocaleDateString('en-GB', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });

  const data = useMemo(() => {
    const transformedData = productsData.map((item) => ({
      name: item.label,
      value: item.value,
    }));
    return transformedData;
  }, [productsData]);

  const headers = [
    { label: "Location Name", key: "name" },
    { label: "Order Count", key: "value" },
  ];
 
  const csvReport = {
    data: data,
    headers: headers,
    filename: `OrderLocation-${formattedStartDate}-to-${formattedEndDate}.csv`
  };

  const dispatch = useDispatch();

  const handleLabelClick = (event, chartContext, config) => {
    const clickedLabelData = chartLabels[config?.dataPointIndex];
    dispatch(dashboardActions.getProductLocation(clickedLabelData));
    setSelectedLabel(clickedLabelData);
  };


  useEffect(() => {
    const startOfMonth = selectedDateRange.start;
    const endOfDay = selectedDateRange.end;
    setStartDate(startOfMonth);
    setEndDate(endOfDay);
    dispatch(dashBoardActions.getProductDetail(startOfMonth.unix() * 1000, endOfDay.unix() * 1000));
  }, [selectedDateRange]);

  const handleDateRangeChange = (startDate, endDate) => {
    if (startDate && endDate) {
      setSelectedDateRange({
        start: dayjs(startDate),
        end: dayjs(endDate),
      });
    }
  };


  const chartOptions = useChart({
    colors: Colors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: {

        expandOnClick: true,
        donut: {
          labels: {
            show: false,

          },
        },
        offsetY: 0,
        offsetX: 0,
      },


      dataLabels: {
        dropShadow: {
          enabled: false,
        },
        style: {
          fontSize: '14px',
          fontFamily: 'Roboto',
        },
      },
    },
    chart: {
      animations: {
        enabled: true,
        easing: 'linear',
        dynamicAnimation: {
          speed: 500,
        },
      },

      events: {
        dataPointSelection: handleLabelClick,
        custom: handleLabelClick,
      },
    },
  });

  return (
    //  <Grid container component="main" spacing={4} className="dashboard-container">
    //  <Grid item xs={12} md={12} lg={12} className="mt-2">
    <>
      <Card {...other}>
      <div className="graph-head">
        <CardHeader title={title} className='title-heading' />
        <div className="date-csv-sec">
          <RangePicker
            value={selectedDateRange}
            onDateSelected={handleDateRangeChange}
          />
          <div className="export-csv">
            <CSVLink {...csvReport}>
              Export CSV
              <GetAppIcon />  
            </CSVLink>
          </div>
        </div>
      </div>
      <StyledChartWrapper dir="ltr">
          {productsData.length !== 0 ?
            <div className='demo'>


              <ReactApexChart
                type="donut"
                series={chartSeries}
                options={chartOptions}
                height={410}
              />



              {/* <Button>
        Prev
      </Button>
      <Button>
        Next
      </Button> */}
              {selectedLabel && (<>
                <Button onClick={() => setSelectedLabel(null)} >
                  {/* <CloseIcon/> */}
                  Clear Data
                </Button>

              </>
              )}
            </div>
            : <Loader />}
        </StyledChartWrapper>
      </Card>
      <Grid item xs={12} md={12} lg={12} className="mt-23">
        <>

        </>
      </Grid>
    </>
  );
};


