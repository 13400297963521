import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as commonActions from "../../../redux/actions/common"

import Dialog from './Dialog';
import { AddCategory, ZipcodeModal, AddBrandModal, AddSpecialProduct, EditPromotions, AddUserModal, AddTruckZipcode } from '../../../containers';
import AddPromotions from '../../../containers/Promotions/AddPromotionsModal';
import CustomConfirmationModal from '../CustomConfirmationModal';

import ViewItems from '../../../containers/Orders/ViewItems'
import ThresholdModal from '../../../containers/ManageProducts/ThresholdModal'
import AddDeliveryHoursModal from "../../../containers/DeliveryHours/AddDeliveryHoursModal"

export default function CustomModal() {
    const dispatch = useDispatch();
    const { modal, modalData } = useSelector(state => state.common);
    const closeModal = () => {
        dispatch(commonActions.closeCustomModalDialog({}))
    }


    switch (modal) {
        case "AddCategory":
            return <RenderModalWithComponent component={AddCategory} customFunction={closeModal} />
        case "ZipCodeModal":
            return <RenderModalWithComponent component={ZipcodeModal} customFunction={closeModal} />
        case "AddBrandModal":
            return <RenderModalWithComponent component={AddBrandModal} customFunction={closeModal} />
        case "ConfirmationModal":
            return <RenderModalWithComponent component={CustomConfirmationModal} customFunction={closeModal} maxWidth="xs" />
        case "AddSpecialProduct":
            return <RenderModalWithComponent component={AddSpecialProduct} customFunction={closeModal} />
        case "AddPromotions":
            return <RenderModalWithComponent component={AddPromotions} customFunction={closeModal} maxWidth="md" />
        case "EditPromotion":
            return <RenderModalWithComponent component={EditPromotions} customFunction={closeModal} maxWidth="md" />
        case "AddUserModal":
            return <RenderModalWithComponent component={AddUserModal} customFunction={closeModal} />
        case "AddTruckZipcode":
            return <RenderModalWithComponent component={AddTruckZipcode} customFunction={closeModal} />
        case "ViewItems":
            return <RenderModalWithComponent component={ViewItems} customFunction={closeModal} maxWidth="md" />
        case "ThresholdModal":
            return <RenderModalWithComponent component={ThresholdModal} modalData={modalData} customFunction={closeModal} maxWidth="sm" />
        case "AddDeliveryHoursModal":
            return <RenderModalWithComponent component={AddDeliveryHoursModal} customFunction={closeModal} maxWidth="md" />
        default:
            return null
    }
}

const RenderModalWithComponent = ({ component: Component, maxWidth = "sm", customFunction, ...rest }) => {
    return (
        <>
            <Dialog fullWidth maxWidth={maxWidth} customFunction={customFunction}>
                <Component {...rest} closeModal={customFunction} />
            </Dialog>
        </>
    )
}

