import { apiGet, apiPost, apiPut, apiDelete } from '../../utils';

export function getAllAdminAPI() {
    return apiGet('api/v1/admin/get');
}

export function getAllAdminDataAPI(data) {
    return apiGet('api/v1/user/get/admin', data);
}

export function addUserAPI(data, id = '') {
    if (id) {
        return apiPut(`api/v1/admin/edit/${id}`, data);
    }
    return apiPost('api/v1/admin/create', data);
}

export function deleteUserAPI(id) {
    return apiDelete(`api/v1/admin/delete/${id}`)
}
