import { useSelector, useDispatch } from 'react-redux';
import { Card, CardHeader, CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import * as productActions from '../../redux/actions/dashboard';
import dayjs from 'dayjs';
import CommonLoading from '../../components/common/CommonLoading';


function Products({ Products, isLoading, title, ...other }) {
  const [activeSeries, setActiveSeries] = useState('Top');
  const [selectedMonthIndex, setSelectedMonthIndex] = useState(dayjs().month());
  const yearOptions = [2022, 2023, 2024];
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedYear)
      dispatch(productActions.getProduct(selectedYear));
  }, [dispatch, selectedYear]);

  const selectedData = Products;
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const handleSeriesClick = (seriesName) => {
    setActiveSeries(seriesName);
  };

  const handleMonthChange = (event) => {
    setSelectedMonthIndex(event.target.value);
  };

  let selectedSeriesData = {
    'Top': [],
    'Least': [],
  };

  if (!selectedData ||
    (!selectedData.monthWiseData?.[selectedMonthIndex]?.topSellingProducts &&
      !selectedData.monthWiseData?.[selectedMonthIndex]?.leastSellingProducts)) {
    return <div>
      {DateFilter()}
      {!isLoading && <div className='loader-ui'><CircularProgress className='loader-ui-style' color="inherit" /> </div>}
    </div>
  }
  const getMonthName = (monthIndex) => {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[monthIndex];
  };

  const selectedMonthData = selectedData.monthWiseData[selectedMonthIndex];
  selectedSeriesData = {
    'Top': selectedMonthData.topSellingProducts || [],
    'Least': selectedMonthData.leastSellingProducts || [],
  };

  const graphData = selectedSeriesData[activeSeries].map(item => ({
    x: item.productName,
    y: item.qty,
  }));

  const chartOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        export: {
          csv: {
            filename: `TopSellingProduct-${selectedMonthIndex + 1}-${selectedYear}`,
          },
          svg: {
            filename: `TopSellingProduct-${selectedMonthIndex + 1}-${selectedYear}`,
          },
          png: {
            filename: `TopSellingProduct-${selectedMonthIndex + 1}-${selectedYear}`,
          }
        }
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    xaxis: {
      categories: graphData?.map(data => data.x),
    },
  };

  const series = [
    {
      name: activeSeries,
      data: graphData.map(data => data.y),
    },
  ];
  function DateFilter() {
    return <>
      <CardHeader title={title} className='title-heading' />
      <div className="filter-container">

        {<div className='bar-graph-btn'>
          {Object.keys(selectedSeriesData).map(seriesName => (
            <button
              key={seriesName}
              onClick={() => handleSeriesClick(seriesName)}
              disabled={seriesName === activeSeries}
              className={seriesName === activeSeries ? 'disabled-btn' : undefined}
              isLoading={isLoading}
            >
              {seriesName}
            </button>
          ))}
        </div>}
        <div className='date-filter '>
          <div className='year-filter'>
            <FormControl>
              <InputLabel>Select Year</InputLabel>
              <Select
                value={selectedYear}
                onChange={handleYearChange}
              >
                {yearOptions.map(year => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className='month-filter'>
            <FormControl>
              <InputLabel>Select Month</InputLabel>
              <Select
                value={selectedMonthIndex}
                onChange={handleMonthChange}
              >
                {selectedData?.monthWiseData && selectedData?.monthWiseData.map((monthData, index) => (
                  <MenuItem key={monthData.month} value={index}>
                    {getMonthName(monthData.month)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
    </>
  }

  // useEffect(() => {
  //   if (selectedMonthData && selectedData) {
  //     setselectedSeriesData({
  //       Top: selectedMonthData.topSellingProducts || [],
  //       Least: selectedMonthData.leastSellingProducts || [],
  //     })
  //   }

  // }, [selectedMonthData])
  return (
    <div  {...other} >
      {/* <CardHeader title={title} className='title-heading' /> */}
      {/* <div className="filter-container">

        {selectedMonthData && <div className='bar-graph-btn'>
          {Object.keys(selectedSeriesData).map(seriesName => (
            <button
              key={seriesName}
              onClick={() => handleSeriesClick(seriesName)}
              disabled={seriesName === activeSeries}
              className={seriesName === activeSeries ? 'disabled-btn' : undefined}
              isLoading={isLoading}
            >
              {seriesName}bb
            </button>
          ))}
        </div>}
        <div className='date-filter '>
          <div className='year-filter'>
            <FormControl>
              <InputLabel>Select Year</InputLabel>
              <Select
                value={selectedYear}
                onChange={handleYearChange}
              >
                {yearOptions.map(year => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className='month-filter'>
            <FormControl>
              <InputLabel>Select Month</InputLabel>
              <Select
                value={selectedMonthIndex}
                onChange={handleMonthChange}
              >
                {selectedData.monthWiseData.map((monthData, index) => (
                  <MenuItem key={monthData.month} value={index}>
                    {getMonthName(monthData.month)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div> */}
      {DateFilter()}
      {
        graphData.length !== 0 ?
          <ReactApexChart options={chartOptions} series={series} type="bar" height={540} />
          :
          <div className="min-no-data">
            <p>No Data Found</p>
          </div>
      }
    </div>
  );
}

export default Products;
