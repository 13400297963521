import { GET_COUPON_DATA,
         GET_COUPON_DATA_SUCCESS,
         GET_COUPON_DATA_FAILURE,
         GET_PRODUCT_DETAIL,
         GET_PRODUCT_DETAIL_SUCCESS,
         GET_PRODUCT_DETAIL_FAILURE,
         GET_PRODUCT_LOCATION,
         GET_PRODUCT_LOCATION_SUCCESS,
         GET_PRODUCT_LOCATION_FAILURE,
         GET_PRODUCT,
         GET_PRODUCT_SUCCESS,
         GET_PRODUCT_FAILED,
         GET_DEVICE,
         GET_DEVICE_SUCCESS,
         GET_DEVICE_FAILURE} from "../actions/dashboard"; 


        const initialState = {
            isLoading: false,
            CouponData:{},
            ProductData:{},
            LocationData:{},
            Products:{},
            Device:{},
        }

        export default function (state = { ...initialState }, action = {}) {
            switch (action.type) {
              case GET_COUPON_DATA:
                return { ...state, isLoading: true };
          
              case GET_COUPON_DATA_SUCCESS:
                return { ...state, CouponData: action.payload || {}, isLoading: false };
          
              case GET_COUPON_DATA_FAILURE:
                return { ...state, isLoading: false };
              
              case GET_PRODUCT_DETAIL:
                  return { ...state, isLoading: true };
            
              case GET_PRODUCT_DETAIL_SUCCESS:
                  return { ...state, ProductData: action.payload || {}, isLoading: false };
            
              case GET_PRODUCT_DETAIL_FAILURE:
                  return { ...state, isLoading: false };
              
              case GET_PRODUCT_LOCATION:
                    return { ...state, isLoading: true };
              
              case GET_PRODUCT_LOCATION_SUCCESS:
                    return { ...state, LocationData: action.payload || {}, isLoading: false };
              
              case GET_PRODUCT_LOCATION_FAILURE:
                    return { ...state, isLoading: false };

              case GET_PRODUCT:
                    return { ...state, isLoading: true };
                
              case GET_PRODUCT_SUCCESS:
                    return { ...state, Products: action.payload || {}, isLoading: false };
                
              case GET_PRODUCT_FAILED:
                    return { ...state, isLoading: false };
                  
              case GET_DEVICE:
                    return { ...state, isLoading: true };
                    
              case GET_DEVICE_SUCCESS:
                    return { ...state, Device: action.payload || {}, isLoading: false };
                    
              case GET_DEVICE_FAILURE:
                    return { ...state, isLoading: false };      
                          
              default:
                return state;
            }
          
          }