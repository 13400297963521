import React, { useEffect, useState } from 'react'
import { isSuperAdminAccess } from "../../utils"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import * as actions from "../../redux/actions/user"
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import * as commonActions from "../../redux/actions/common"
import { getData, } from '../../utils';

import {
    Grid,
    CircularProgress,
    TableContainer,
    Table,
    Button,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    Box
} from "@material-ui/core";
import CommonLoading from '../../components/common/CommonLoading'

function User() {
    const history = useHistory()
    const dispatch = useDispatch()

    const {
        allAdminData = [],
        // allAdmins = [],
        // totalAdmins = 0,
        allAdminLoader = false,
        // adminLoader = false
    } = useSelector(state => state.user) || {};

    const allAdminList = JSON.parse(getData('pineappleExpress-admin'))?.adminData || [];
    const superAdminCheck = allAdminList[0]?.isSuperAdmin;
    const userEmail = allAdminList[0]?.email;

    const apiUserData = () => {
        dispatch(actions.getAllAdminData({ email: allAdminList[0]?.email }))
    };
    useEffect(() => apiUserData(), []);


    // To Hide the user for admin another than super admin
    // useEffect(() => {
    // if (!isSuperAdminAccess()) {
    //     return history.push('/')
    // }
    // dispatch(actions.getAllAdmin())
    // }, [])

    const handleConfirmation = (id) => {
        dispatch(commonActions.openCustomModalDialog({
            title: "Are you sure you want to Delete?",
            confirm: () => { onDeleteAdmin(id) },
            cancel: () => { }
        }, "ConfirmationModal"))
    }

    const onDeleteAdmin = (id) => {
        dispatch(actions.deleteUser(id)).then(res => {
            apiUserData();
            dispatch(actions.getAllAdmin())
        })
    }

    const onEditAdmin = (item) => {
        dispatch(commonActions.openCustomModalDialog({
            title: "Edit",
            data: item
        }, "AddUserModal"))
    }

    return (
        <Grid container component="main" className="admin-container">
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container>
                    {
                        superAdminCheck ?
                            <Grid item xs={12}>
                                <div className="custom-button-div">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => { dispatch(commonActions.openCustomModalDialog({}, "AddUserModal")) }}
                                    >Add User</Button>
                                </div>
                            </Grid> : null
                    }

                    <Grid item xs={12}>
                        <Box mt={3}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>S.no</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Email</TableCell>
                                            {/* <TableCell>Password</TableCell> */}
                                            <TableCell>Created At</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* {
                                            Array.isArray(allAdmins) && allAdmins.length ? allAdmins.map((item, index) => {
                                                return <UserTableRow
                                                    item={item}
                                                    key={item._id}
                                                    index={index}
                                                    onDeleteAdmin={handleConfirmation}
                                                    onEditAdmin={onEditAdmin}
                                                />
                                            }) : <TableRow>
                                                <TableCell colSpan="5" align="center">No Admin Found.</TableCell>
                                            </TableRow>
                                        } */}
                                        {
                                            allAdminData?.length ? allAdminData?.map((item, index) => {
                                                return <UserTableRow
                                                    item={item}
                                                    key={item._id}
                                                    index={index}
                                                    onDeleteAdmin={handleConfirmation}
                                                    onEditAdmin={onEditAdmin}
                                                    superAdminCheck={superAdminCheck}
                                                    userEmail={userEmail}
                                                />
                                            }) : <TableRow>
                                                <TableCell colSpan="5" align="center">No Admin Found.</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <CommonLoading isLoading={allAdminLoader} />
        </Grid>
    )
}

export default User


const UserTableRow = (props) => {
    const { item, index, onDeleteAdmin, onEditAdmin, superAdminCheck, userEmail } = props;
    const [show, setShow] = useState(false);
    const deleteBtnCheck = superAdminCheck && !(userEmail === item.email);


    const onChangeShow = () => {
        setShow(!show)
    }
    return (
        <TableRow >
            <TableCell>{index + 1}</TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.email}</TableCell>
            {/* <TableCell className="password-table-row">
                <span className="show-icon">{show ? <VisibilityIcon onClick={onChangeShow} /> : <VisibilityOffIcon onClick={onChangeShow} />}</span>
                <span className="password-span">
                    {show ? item.password : <span style={{ fontSize: 20 }}>{String(item.password).split('').map(() => <>&bull;</>)}</span>}
                </span>
            </TableCell> */}
            <TableCell>{new Date(item.createdDate).toLocaleDateString()}</TableCell>
            <TableCell><div>
            <span className="cur-pointer edit-icon" onClick={() => { onEditAdmin(item) }}><EditIcon /></span>
            {deleteBtnCheck ?<span className="cur-pointer delete-icon" onClick={() => onDeleteAdmin(item._id)}><DeleteOutlineIcon /></span> : null }

                {/* <EditIcon className="cursor-pointer edit-icon" onClick={() => onEditAdmin(item)} /> */}
                {/* {deleteBtnCheck ? <DeleteOutlineIcon className="cursor-pointer delete-icon" onClick={() => onDeleteAdmin(item._id)} /> : null} */}
            </div></TableCell>
        </TableRow>
    )

}